<template>

  <div slot="no-body" class="tabs-container px-6 pt-6">

      <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
        <vs-tab label="Cafetería" icon-pack="feather" icon="icon-circle">
          <div class="tab-text">
            <coffeeshop-table/>
          </div>
        </vs-tab>
        <vs-tab label="Escuela" icon-pack="feather" icon="icon-home">
          <div class="tab-text">
            <school-table/>
          </div>
        </vs-tab>
        <vs-tab label="Tienda" icon-pack="feather" icon="icon-shopping-cart">
          <div class="tab-text">
            <store-table/>
          </div>
        </vs-tab>
        <!-- <vs-tab label="Pago Directo" icon-pack="feather" icon="icon-chevrons-right">
          <div class="tab-text">
            <direct-payment-table/>
          </div>
        </vs-tab> -->
        <vs-tab label="Superadmin" icon-pack="feather" icon="icon-globe">
          <div class="tab-text">
            <superadmin-table/>
          </div>
        </vs-tab>
      </vs-tabs>

    </div>

</template>

<script>
import CoffeeshopTable from './PendingPaymentsCoffeeshop.vue'
import SchoolTable from './PendingPaymentsSchool.vue'
import StoreTable from './PendingPaymentsStore.vue'
import SuperadminTable from './PendingPaymentsSuperadmin.vue'
import DirectPaymentTable from './PendingPaymentsDirectPayment.vue'
import reportModule from "@/store/report/moduleReport.js";

export default {
  components: {
      // UserListTab
      CoffeeshopTable,
      SchoolTable,
      StoreTable,
      SuperadminTable,
      DirectPaymentTable
  },
  data() {
  return {
    activeTab: 0,
  }
  },
  mounted() {
  },
  created() {
    //Modules
    if (!reportModule.isRegistered) {
      this.$store.registerModule("reportModule", reportModule);
      reportModule.isRegistered = true;
    }
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
