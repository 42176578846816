<template>
  <div class="vx-card p-6">
      <h5 style="margin-bottom:9px">Solicitudes de Pago - Administradores de Cafeterías</h5>
      <!-- TABLE ACTION ROW -->
      <div class="flex flex-wrap justify-between items-center">
        <!-- ITEMS PER PAGE -->
        <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2"
                >{{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }}
                -
                {{
                  pendingPayments.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : pendingPayments.length
                }}
                de {{ pendingPayments.length }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <div
          class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
          <vs-input
            class="mb-4 md:mb-0 mr-4"
            v-model="searchQuery"
            @input="updateSearchQuery"
            placeholder="Buscar..."/>
          <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()"
            >Exportar</vs-button>
        </div>

      </div>
      <ag-grid-vue
        ref="agGridTable"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="pendingPayments"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        :modules="modules">
      </ag-grid-vue>
      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage"
      />
      <sidebar-payments :isSidebarActive="paymentsSidebarActive" @closeSidebar="toggleDataSidebar" :data="sidebarData"></sidebar-payments>
  </div>
</template>
<script>
import { AgGridVue } from "@ag-grid-community/vue";
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import CellRendererActionsPendingPayments from "./cell-renderer/CellRendererActionsPendingPayments.vue";
import SidebarPayments from './SidebarPayments.vue';

export default {
  components: {
    AgGridVue,
    CellRendererActionsPendingPayments,
    SidebarPayments
  },
  data() {
    return {
      modules: AllCommunityModules,

      paymentsSidebar: false,
      paymentsSidebarActive: false,
      sidebarData: {},

      //Table
      searchQuery: "",
      gridOptions: {},

      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      groupHeaderHeight: 150,
      headerHeight: 100,
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          width: 75,
          filter: true,
        },
        {
          headerName: "Administrador",
          field: "owner",
          width: 180,
          filter: true,
          floatingFilter: true
        },
        {
          headerName: "Cafeteria",
          field: "name_institution",
          width: 250,
          filter: true,
          floatingFilter: true
        },
        {
          headerName: "Ventas Tiptap",
          field: "tiptap_total",
          width: 120,
          filter: true,
          valueFormatter: params => this.$options.filters.currencySign(params.data.tiptap_total, '$ '),
        },
        {
          headerName: "Recargas Efectivo",
          field: "recharges_total",
          width: 120,
          filter: true,
          valueFormatter: params => this.$options.filters.currencySign(params.data.recharges_total, '$ '),
        },
        {
          headerName: "Comisión Tiptap",
          field: "tiptap_commission",
          width: 120,
          filter: true,
          valueFormatter: params => this.$options.filters.currencyPercentage(params.data.tiptap_commission, ' %'),
        },
        {
          headerName: "Total Comisión",
          field: "commission_total",
          width: 120,
          filter: true,
          valueFormatter: params => this.$options.filters.currencySign(params.data.commission_total, '$ '),
        },
        {
          headerName: "Total a Pagar",
          field: "total_pay",
          width: 120,
          filter: true,
          valueFormatter: params => this.$options.filters.currencySign(params.data.total_pay, '$ '),
          cellStyle: {color: 'blue', fontWeight: '600'}
        },
        {
          headerName: "Fecha de Solicitud",
          field: "created_at",
          width: 140,
          filter: true,
          floatingFilter: true,
          comparator: this.myDateComparator,
        },
        {
          headerName: "Acción",
          filter: true,
          cellRendererFramework: 'CellRendererActionsPendingPayments',
          cellRendererParams: {
            openSideBar: this.openSideBar.bind(this)
          }
        },
        {
          headerName: "Banco",
          field: "bank_name",
          filter: true,
          floatingFilter: true
        },
        {
          headerName: "Nombre de Cuenta",
          field: "account_name",
          filter: true,
          floatingFilter: true
        },
        {
          headerName: "Tipo de Cuenta",
          field: "account_type",
          filter: true,
          floatingFilter: true
        },
        {
          headerName: "Nro. de Cuenta",
          field: "bank_account",
          filter: true,
          floatingFilter: true
        },
      ],
      // Cell Renderer Components
      components: {
          CellRendererActionsPendingPayments,
      },
      pendingPayments: [],
    };
  },

  created() {
    this.loadPendingPaymentsTables();
  },

  methods: {
    isDate(val) {
      return val instanceof Date && !isNaN(val.valueOf())
    },

    loadPendingPaymentsTables() {
      this.$store.dispatch("reportModule/getPendingPayments")
        .then((response) => {
          if (response.data.requests.length > 0) {
            let s = JSON.parse(JSON.stringify(response.data.requests));

            s.forEach((element) => {
              element.created_at = new Date(element.created_at).toLocaleDateString("es-AR", this.$localeDateOptions);
              element.updated_at = new Date(element.updated_at).toLocaleDateString("es-AR", this.$localeDateOptions);
              // element.status_description = this.$store.state.pendingStatus.statusPending.find(p => p.status_type == element.status).status_type_description;

              response.data.account.forEach((account) => {
                if(element.id_owner == account.id_account) {
                  element.owner = account.name + " " + account.last_name;
                }
              });
              let bank = response.data.banks.find(b => b.id_account == element.id_owner)
              if (bank && bank !== null) {
                element.bank_name = bank.bank_name
                element.account_name = bank.account_name
                element.account_type = this.$store.state.AppData.bankAccountTypes.find(b => b.account_type == bank.account_type).account_type_description
                element.bank_account = bank.bank_account
              }
             }),

            this.pendingPayments = s;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleDataSidebar(val=false) {
      this.paymentsSidebarActive = val
    },
    openSideBar() {
      this.toggleDataSidebar(true);
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApiSuperAdmin.setQuickFilter(val);
      this.gridApiSchool.setQuickFilter(val);
    },
    myDateComparator(date1, date2) {
      var date1Number = this.monthToComparableNumber(date1);
      var date2Number = this.monthToComparableNumber(date2);
      if (date1Number === null && date2Number === null) {
        return 0;
      }
      if (date1Number === null) {
        return -1;
      }
      if (date2Number === null) {
        return 1;
      }
      return date1Number - date2Number;
    },
    monthToComparableNumber(date) {
      if (date === undefined || date === null || date.length !== 10) {
        return null;
      }
      var yearNumber = date.substring(6, 10);
      var monthNumber = date.substring(3, 5);
      var dayNumber = date.substring(0, 2);
      var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
      return result;
    },
  },

  watch: {
    paymentsSidebarActive: function () {
      this.toggleDataSidebar(this.paymentsSidebarActive)
    },
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 7;
        // this.gridOptions.columnApi.setColumnPinned('email', null)
      }
      //   else this.gridOptions.columnApi.setColumnPinned('email', 'left')
    },
  },

  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },

  mounted() {
    this.gridApi = this.gridOptions.api;

    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }
  },
  beforeMount() {
    this.gridOptions = {
      context: {
        componentParent: this
      }
    };
  },
};

</script>
<style>
.ag-header-cell-label .ag-header-cell-text{
  overflow: visible;
  text-overflow: clip;
  white-space: normal;
}
</style>
